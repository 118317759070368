<template>
  <div class="view--privacy-policy">
    <content-section
      type="simple"
      :title="$localize.get('privacy_policy')"
      center-buttons
    >
      <template v-slot:content>
        <div v-html="$localize.get('privacy_policy_content')"></div>
      </template>
    </content-section>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.view--privacy-policy {
  h3 {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}
</style>